<template>
  <section class="h-100">

    <div style="padding: 40px;">
      <div class="m-auto" style="display: inline-block;">
        <img
          src="@/assets/images/relatus_orange_logo_horizontal.png"
          style="max-height: 60px;"
        >
      </div>

      <div style="display: inline-block;float: right;margin-top: 13px;">
        <span class="uppercase">Pre-register</span>
        <img
          src="@/assets/images/menu.svg"
          style="display: inline-block;margin-left: 20px;"
        >
      </div>
    </div>

    <div class="flex h-screen text-center" style="height:80vh;">

      <div class="m-auto pb-10" style="max-width: 800px;">

        <div class="mb-12 relative">
          <router-link
            :to="{name: 'registration.v2'}"
            class="absolute left-0"
          >
            <img
              src="@/assets/images/icons/ic_chevron_left.svg"
              class="inline-block"
            > Back
          </router-link>

          <span>Approximately 5 min. to complete</span>
        </div>

        <h1 class="mb-9">Welcome to Relatus!</h1>

        <h4 class="text text-xl font-bold mb-6">Your profile for life</h4>

        <p class="mb-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Augue lacus viverra vitae congue eu consequat ac felis. Et netus et malesuada fames ac turpis egestas maecenas. Nunc sed id semper risus in hendrerit gravida rutrum. Ipsum dolor sit amet consectetur adipiscing elit. Blandit cursus risus at ultrices mi tempus imperdiet. In mollis nunc sed id semper risus in.</p>

        <p class="mb-6">Consequat semper viverra nam libero justo laoreet sit amet cursus. Et ultrices neque ornare aenean euismod elementum. Sed id semper risus in. Tincidunt dui ut ornare lectus sit amet est placerat. Mauris cursus mattis molestie a iaculis at erat pellentesque. Viverra ipsum nunc aliquet bibendum enim facilisis gravida neque.</p>

        <p class="mb-6">Aliquam vestibulum morbi blandit cursus risus at ultrices mi. Sodales neque sodales ut etiam sit amet. In cursus turpis massa tincidunt. Massa vitae tortor condimentum lacinia quis vel eros donec. In mollis nunc sed id. Egestas dui id ornare arcu odio ut sem. Condimentum vitae sapien pellentesque habitant.</p>

        <p class="mb-6">Eu mi bibendum neque egestas congue. Egestas fringilla phasellus faucibus scelerisque eleifend donec. In massa tempor nec feugiat nisl pretium fusce id velit. Tincidunt dui ut ornare lectus sit amet est placerat. Amet purus gravida quis blandit turpis. Leo integer malesuada nunc vel risus commodo viverra.</p>

        <div class="mt-10 text-center">
          <span class="dot active"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>

        <div style="margin-top: 60px;">
          <router-link
            :to="{name: 'registration.v2.signup.name'}"
            class="btn btn-primary align-self-center me-auto mt-8"
          >
            Get Started
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Welcome',
  mounted() {
    if (this.$route.query.name) {
      console.log(this.$route.query.name);
      let fullName = this.$route.query.name;
      let firstName = fullName.split(' ').slice(0, -1).join(' ');
      let lastName = fullName.split(' ').slice(-1).join(' ');

      localStorage.setItem("firstname", firstName);
      localStorage.setItem("lastname", lastName);
    }
  }
}
</script>

<style scoped>
h3 {
  font-family: 'Helvetica';
  font-size: 54px;
  line-height: 70px;
  color: #FFFFFF;
  letter-spacing: 1px;
  font-weight: 300;
}
h3 > strong {
  font-weight: bold;
}
</style>
